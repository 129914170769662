import React, { useEffect, useState } from "react";
import { getComboFillForProcess, getProcessDefinition, getProcessDefinitionData } from "../../services/generic";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";import moment from "moment";
import { useGlobalContext } from "../../lib/storage";
import { DatePicker, Select, Button, Table, Tabs, Row, Skeleton } from "antd";

const { RangePicker } = DatePicker; // For date range selection
const { Option } = Select;
const { TabPane } = Tabs;

const ProcessWindow = () => {
  const { globalStore } = useGlobalContext();
  const userPreferences = globalStore.userPreferences;
  const dateFormat = userPreferences.dateFormat;
  const [processData, setProcessData] = useState([]);
  const [processDefinition, setProcessDefinition] = useState();
  const [dropdownDetails, setDropdownDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState([moment().subtract(1, "month"), moment()]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [badges, setBadges] = useState([]);
  const [tabs, setTabs] = useState([])
  const [loading, setLoading] = useState(false); // For loading state

  const { processId } = useParams();

  // Fetch Process Definition
  useEffect(() => {
    const definitionProcess = async () => {
      try {
        const processDefinition = await getProcessDefinition(processId);
        console.log(JSON.parse(processDefinition));
        setProcessDefinition(JSON.parse(processDefinition));
      } catch (error) {
        console.error("Error fetching process definition:", error);
      }
    };

    definitionProcess();
  }, [processId]);

  useEffect(() => {
    if (processData && processDefinition) {
      const badgesArray = processDefinition.Data.filter(item => item.type === "Badge").map(badge => ({
        name: badge.name,
        fields: JSON.parse(badge.fields)
      }));
      const tabsArray = processDefinition.Data.filter(item => item.type === "Tab").map(tab => ({
        name: tab.name,
        fields: tab.fields
      }));
      setBadges(badgesArray);
      setTabs(tabsArray);
    }
  }, [processData, processDefinition]);

  // Fetch Dropdown Data
  const onDropDownSelect = async (dashboard, id, value) => {
    try {
      const getFilterData = await getComboFillForProcess(dashboard, id, value);
      if (getFilterData && typeof getFilterData[Symbol.iterator] === "function") {
        setDropdownDetails([...getFilterData]);
      } else {
        console.error("Error getting dropdown details");
      }
    } catch (error) {
      console.error("Error in onDropDownSelect:", error);
    }
  };

  // Handle Apply Button Click
  const handleApply = async () => {
    setLoading(true); // Set loading to true when applying filters
    try {
      const payload = {
        Date: selectedDate.map((date) => moment(date).format(dateFormat)), // Format the date range
        cs_bunit_id: businessUnit, // Selected Business Units
      };

      console.log("Payload for fetchProcessData:", payload);
      const stringifiedJSON = JSON.stringify(payload);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      console.log(jsonToSend);
      // Fetch process data using a service
      const processData = await getProcessDefinitionData(processId, jsonToSend);
      console.log("Fetched Process Data:", processData);
      setProcessData(JSON.parse(processData.data)); // Assuming the response data is in 'data'
    } catch (error) {
      console.error("Error in handleApply:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  {/* Render Badges */}
  const renderBadges = () => {
    if (loading) return <Skeleton active />;
    if (!processDefinition || !processData) return <div>No Badges Found</div>;

    const badges = processDefinition.Data.filter(item => item.type === "Badge");

    return (
      <Row gutter={[16, 16]}>
        {badges.map((badge, index) => {
          const badgeValue = processData[badge.id];
          return (
            <div key={index} style={{
              background: "#fff",
              padding: "1rem",
              borderRadius: "8px",
              border:"1px solid #dfdfdf",
              minWidth: "200px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            }}>
              <span style={{fontFamily:"Inter",fontWeight:500,fontSize:"12.6px"}}>{badge.name}</span>
              <br/>
              <span style={{ fontSize: "24px", fontWeight: 700 }}>{badgeValue}</span>
            </div>
          );
        })}
      </Row>
    );
  };

  {/* Render Tabs */}
  const renderTabs = () => {
    if (loading) return <Skeleton active />;
    if (!processDefinition || !processData) return <div>No Data Available</div>;
  
    const tabs = processDefinition.Data.filter(item => item.type === "Tab");
  
    return (
      <>
        <Tabs defaultActiveKey="1" tabBarStyle={{background:"white"}}>
          {tabs.map((tab, index) => {
            const tableData = processData[tab.id] || [];
  
            return (
              <TabPane tab={tab.name} key={index}>
               <div style={{ overflowX: 'auto' }}>
                <div style={{ maxHeight: '40vh', overflowY: 'auto' }}> {/* Set a max height for the table */}
                    <table
                    style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        fontFamily:"Inter",
                    }}
                    >
                    <thead style={{fontFamily:"Inter",color:"#71717a",fontWeight:500,borderBottom:"1px solid #dfdfdf"}}> 
                        <tr>
                        {JSON.parse(tab.fields).map((field, idx) => (
                            <th
                            key={idx}
                            style={{
                                position: 'sticky',
                                top: 0, // This makes the header stick to the top
                                backgroundColor: '#fff', // Ensure header background is white to cover content below it
                                zIndex: 1, // Makes sure header stays on top
                                borderBottom: '1px solid #ddd',
                                padding: '8px',
                                textAlign: 'left',
                              }}
                            >
                            {field.name}
                            </th>
                        ))}
                        </tr>
                    </thead>
                    <tbody style={{font:" Inter", fontSize:"13px",fontWeight:400}}>
                    {loading ? (
                        <tr>
                            <td colSpan={JSON.parse(tab.fields).length} style={{ textAlign: "center", padding: "20px" }}>
                            <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />
                            </td>
                        </tr>
                        ) : tableData.length > 0 ? (
                        tableData.map((row, rowIndex) => (
                            <tr style={{backgroundColor: '#fff'}} key={rowIndex}>
                            {JSON.parse(tab.fields).map((field, colIndex) => (
                                <td
                                key={colIndex}
                                style={{
                                    borderBottom: '1px solid #ddd',
                                    padding: '8px',
                                    textAlign: 'left',
                                }}
                                >
                                {row[field.columnname] || '-'}
                                </td>
                            ))}
                            </tr>
                        ))
                        ) : (
                        <tr>
                             <td
                                colSpan={JSON.parse(tab.fields).length}
                                style={{
                                    textAlign: "center",
                                    padding: "20px",
                                    fontWeight: "bold",
                                    color: "#71717a",
                                }}
                                >
                                No Data Available
                                </td>
                        </tr>
                        )}
                    </tbody>
                    </table>
                </div>
                </div>

              </TabPane>
            );
          })}
        </Tabs>
      </>
    );
  };
  



  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Vertically center items
        }}
      >
        {/* Process Name */}
        <span style={{ fontFamily:"Inter",fontWeight:550,fontSize:"23px",color:"#161537" }}>
          {processDefinition?.name || <Skeleton.Input style={{ width: 200 }} active />}
        </span>

        {/* Filters and Button */}
        <div style={{ display: "flex", gap: "1rem", alignItems: "end" }}>
          {processDefinition?.Filters.map((filter, index) => {
            if (filter.type === "DateRange") {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start", // Align labels and inputs properly
                  }}
                >
                  <label style={{ marginBottom: "0.25rem",fontFamily:"Inter",fontSize:"12px",fontWeight:500 }}>{filter.displayName}</label>
                  <RangePicker
                    defaultValue={[moment().subtract(1, "month"), moment()]} // Last 1 month range
                    format={dateFormat}
                    onCalendarChange={(val) => {
                      if (val) {
                        const arr = val.map((date) => moment(date).format(dateFormat));
                        console.log("Selected Dates:", arr);
                        setSelectedDate(arr);
                      }
                    }}
                  />
                </div>
              );
            }

            if (filter.type === "MultiSelector") {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start", // Align labels and inputs properly
                  }}
                >
                  <label style={{ marginBottom: "0.25rem",fontFamily:"Inter",fontSize:"12px",fontWeight:500 }}>{filter.displayName}</label>
                  <Select
                    mode="multiple"
                    maxTagCount={1}
                    listHeight={100}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ minWidth: "200px",borderRadius:"5px" }}
                    onSearch={(value) => onDropDownSelect(processId, filter.id, value)}
                    onFocus={() => onDropDownSelect(processId, filter.id)}
                    placeholder={`Select ${filter.displayName}`}
                    onChange={(e) => {
                      setBusinessUnit(e);
                    }}
                  >
                    {dropdownDetails?.map((data) => (
                      <Option key={JSON.parse(data).recordid} value={JSON.parse(data).recordid}>
                        {JSON.parse(data).name}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            }

            return null;
          })}

          {/* Button */}
          <Button type="primary" style={{ marginTop: "0.5rem",fontFamily:"Roboto",fontSize:"14px",fontWeight:600,borderRadius:"5px",letterSpacing:"0.5px" }} onClick={handleApply} loading={loading}>
            Apply
          </Button>
        </div>
      </div>
      <div style={{padding:"0.5rem"}}>
        {renderBadges()}
      </div>

      <div style={{ marginTop: '20px' }}>
        {renderTabs()}
      </div>
     </div>
  );
};

export default ProcessWindow;
